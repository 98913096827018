import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from '../../../../Components/Toast/Toast';


export default function UpdateImage(props) {
  const history = useHistory();
  const [images, setImages] = useState([]);
  const location = useLocation();
  const baseUrl = "https://api.anchorswap.app/api/account/";


  console.log("Inside")
  const [link, setLink] = useState(location.state.userLink);
  const [selectedFile, setSelectedFile] = useState(location.state.imageName);
  console.log("selectedFile: ", selectedFile)
  function myHandler(e) {
    setSelectedFile(e.target.files[0]);
    var selectableMaxFileSize = 1024 * 1024 * 1; // 1 Megabyte
    var initialFileSize = 0;
    if (e.target.files) {
      for (var i = 0; i < e.target.files.length; i++) {
        var file = e.target.files[i];
        initialFileSize += file.size;
      }
      if (initialFileSize > selectableMaxFileSize) {
        setSelectedFile(e.target.files[0]);
        e.target.value = '';
        toast.error("file too large")
        return;
      }
    }
  }


  async function submitHandler(e) {
    e.preventDefault();
    var url = baseUrl + "updatedetails/" + location.state.id;
    const formdata = new FormData();
    formdata.append('myFile', selectedFile);
    formdata.append('url', link);

    let response = await axios.patch(url, formdata);
    console.log(response);
    console.log(selectedFile)

    var selectableMaxFileSize = 1024 * 1024 * 10; // 10 Megabyte
    var initialFileSize = 0;



    history.push({
      pathname: '/admin-dashboard/addImages'
    })

  }

  function getImage() {
    axios.get(baseUrl + "getname&url")
      .then((res) => {
        setImages(res.data);
        console.log(res.data)
      });
  }

  useEffect(() => {
    console.log(location.state.imageName)
    getImage()
  }, [])
  return (
    <div>
      <input type="text" value={link} onChange={e => setLink(e.target.value)} className="form-control" placeholder='Paste your link here' /><br />
      <img src={baseUrl + "get/" + location.state.imageName} width="159" height="150" />

      <input type="file" name='myFile' accept='.jpg, .jpeg, .png' onChange={myHandler} />
      <input type="button" className="upload_btn" value="Update" onClick={submitHandler} /><br />

    </div>
  )
}