import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from '../../../../Components/Toast/Toast';
import "./../../admin/dashboard-bsc/bsc.scss"

function AddImages(props) {
  const [link, setLink] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [images, setImages] = useState([]);
  const baseUrl = "https://api.anchorswap.app/api/account/";



  function myHandler(e) {
    setSelectedFile(e.target.files[0]);
    var selectableMaxFileSize = 1024 * 1024 * 1; // 1 Megabyte
    var initialFileSize = 0;
    if (e.target.files) {
      for (var i = 0; i < e.target.files.length; i++) {
        var file = e.target.files[i];
        initialFileSize += file.size;
      }
      if (initialFileSize > selectableMaxFileSize) {
        setSelectedFile(e.target.files[0]);
        e.target.value = '';
        toast.error("file too large")
        return;
      }
    }

  }

  async function submitHandler(e) {
    e.preventDefault();
    var url = baseUrl + "upload";
    const formdata = new FormData();
    formdata.append('myFile', selectedFile);
    formdata.append('url', link);


    let response = await axios.post(url, formdata);


    console.log(response);
    if (!selectedFile) {
      toast.error('Not Posted ')
    }
    else {
      toast.success('Posted Successfully')

    }
    console.log(selectedFile)
  }

  function updateImage(img) {
    console.log("Update" + img.fileName);


    props.history.push({
      pathname: '/UpdateImage',
      state:
      {
        imageName: img.fileName,
        userLink: img.fileUrl,
        id: img._id
      }

    })

  }



  function deleteImage(img) {
    alert("Delete " + img._id);
    axios.delete(`${baseUrl}deleteimage/${img._id}`)
      .then((res) => {
        console.log('Deleted image', img._id)
        toast.success('Deleted Successfully')
      })

      .catch(err => {
        console.log("Could not delete", err)
        toast.error('Not Deleted')
      })

  }
  useEffect(() => {
    getImage()
  }, [deleteImage])

  function getImage() {
    axios.get(baseUrl + "getname&url")
      .then((res) => {
        setImages(res.data);
      });
  }

  const arr = images.map((img, index) => {
    return (
      <tr>
        <td style={{ border: '2px solid white', padding: '10px 20px', textAlign: 'center', fontSize: '18px' }}>{index + 1}</td>
        <td style={{ border: '2px solid white', padding: '10px 20px', textAlign: 'center', fontSize: '18px' }}>{img.fileName}</td>
        <td style={{ border: '2px solid white', padding: '10px 20px', textAlign: 'center', fontSize: '18px' }}>{img.fileUrl}</td>
        <td style={{ border: '2px solid white', padding: '20px', textAlign: 'center', fontSize: '18px' }}>{<img src={"https://api.anchorswap.app/images/" + img.fileName} width="159" height="150" />}</td>
        <td style={{ border: '2px solid white', padding: '20px', textAlign: 'center', fontSize: '18px' }}><button className='delete_btn' onClick={() => { deleteImage(img) }}>Delete</button></td>
        <td style={{ border: '2px solid white', padding: '20px', textAlign: 'center', fontSize: '18px' }}><button className='delete_btn' onClick={() => { updateImage(img) }}>Update</button></td>
      </tr>
    )
  })

  return (
    <section>
      <input type="text" defaultValue={link} onChange={e => setLink(e.target.value)} className="form-control" placeholder='Paste your link here' /><br />
      <div>
        <input type="file" name='myFile' accept='.jpg, .jpeg, .png' onChange={myHandler} required /><br />
        <br /><br />
        <br />

      </div>
      <input type="button" className="upload_btn" value="Upload" onClick={submitHandler} /><br />
      <br />
      <br />
      <br />
      <br />

      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ border: '2px solid white', color: '#e2d535', padding: '20px', textAlign: 'center', fontSize: '20px', borderTopLeftRadius: '10px' }}>Index</th>
            <th style={{ border: '2px solid white', color: '#e2d535', padding: '20px', textAlign: 'center', fontSize: '20px' }}>Name</th>
            <th style={{ border: '2px solid white', color: '#e2d535', padding: '20px', textAlign: 'center', fontSize: '20px' }}>Link entered by user</th>
            <th style={{ border: '2px solid white', color: '#e2d535', padding: '20px', textAlign: 'center', fontSize: '20px' }}>Image</th>
            <th style={{ border: '2px solid white', color: '#e2d535', padding: '20px', textAlign: 'center', fontSize: '20px' }}>Delete</th>
            <th style={{ border: '2px solid white', color: '#e2d535', padding: '20px', textAlign: 'center', fontSize: '20px' }}>Update</th>

          </tr>
          {arr}

        </tbody>
      </table>

    </section   >
  )
}

export default AddImages;