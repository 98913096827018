import React, { Component } from "react";
import {


  Route,
  Switch, Redirect
} from "react-router-dom";
import AuthGuard from "./routes/Guards/AuthGuard";
import PrivateRoutes from "./routes/PrivateRoutes/PrivateRoutes";
import AdminLogin from "./Pages/PrivatePages/admin/login/Index";
import AdminDashboard from "./Pages/PrivatePages/AdminDashboard";
import { connect } from "react-redux";
import {
  changeTheme,
} from "./redux/actions/ethereum.action";

import { connectMetamask } from "./redux/actions/ethereum.action";
import UpdateImage from "./Pages/PrivatePages/admin/dashboard-addImages/UpdateImage";
import { ImageGroup } from "semantic-ui-react";
class Application extends Component {

  componentWillMount = () => {
    if (this.props.metaMaskAddress) {
      this.props.getData();
    }
    if (this.props.lightMode) {
      document.body.classList.remove("dark-theme");
      document.body.classList.add("light-theme");
    } else {
      document.body.classList.remove("light-theme");
      document.body.classList.add("dark-theme");
    }
  };
  showDarkTheme = async (checked) => {
    this.props.changeTheme(checked);
    if (checked) {
      document.body.classList.remove("dark-theme");
      document.body.classList.add("light-theme");
    } else {
      document.body.classList.remove("light-theme");
      document.body.classList.add("dark-theme");
    }
  };
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/" render={() => (
            <Redirect to="/admin-login" />
          )} />
          <Route
            path={`/auth`}
            render={(props) => (
              <AuthGuard
                {...props}
                showDarkTheme={this.showDarkTheme}
                component={PrivateRoutes}
              />
            )}
          />
          {/* <Route
            path={`/admin`}
            render={(props) => (
              <AdminAuthGuard
                {...props}
                showDarkTheme={this.showDarkTheme}
                component={AdminRoutes}
              />
            )}
          /> */}
          <Route path={`/admin-login`} component={AdminLogin} />
          {this.props.isLoggedIn ? <Route path={`/admin-dashboard`} component={AdminDashboard} /> : <Redirect to="/admin-login" />}

          <Route path={`/admin-login`} component={AdminLogin} />
          {this.props.isLoggedIn ? <Route path={`/UpdateImage/`} component={UpdateImage} /> : <Redirect to="/admin-login" />}
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user: { metaMaskAddress, lightMode }, admin: { isLoggedIn } }) => ({
  metaMaskAddress,
  lightMode,
  isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(connectMetamask()),
  changeTheme: (lightMode) => dispatch(changeTheme(lightMode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Application);
