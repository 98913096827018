import { EthereumService } from "../../services/EthereumService";
import { toast } from "../../Components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import { AdminService } from "../../services/AdminService";
import { ContractServices } from "../../services/ContractServices";
import { MASTERCHEF } from "../../constant";
import { MAIN_CONTRACT_LIST } from "../../assets/tokens";
import MASTERCHEFABI from "../../assets/ABI/masterchef.json";

export function getDBOwner() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const currencies = await EthereumService.getDBOwner();
            dispatch(stopLoading());
            return currencies
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(error.message);
        }
    };
}

export function transferOwnership(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const result = await EthereumService.transferOwnership(data)
            dispatch(stopLoading());
            return result
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(error.message);
        }
    };
}

export function addFunction(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const web3 = await EthereumService.callWeb3();
            const address = await web3.eth.getAccounts();

            let newArray = [];
            for (let i = 0; i < data.params.length; i++) {
                newArray.push(data.params[i].value);
            }
            const abi = MASTERCHEFABI.find(element => element.name === data.name);
            const signParaTransfer = await web3.eth.abi.encodeFunctionCall(abi, newArray);
            data.signParaTransfer = signParaTransfer;

            const contract = await ContractServices.callContract(MAIN_CONTRACT_LIST.timeLock.address, MAIN_CONTRACT_LIST.timeLock.abi);

            let date = new Date()
            let secondsSinceEpoch = Math.round(date.getTime() / 1000)
            let queuedTime = secondsSinceEpoch + 190;
            data.queuedTime = queuedTime;
            const gasPrice = await ContractServices.calculateGasPrice();
            const value = await web3.utils.toHex(0);
            const gas = await contract.methods.queueTransaction(MASTERCHEF, "", signParaTransfer, queuedTime).estimateGas({ from: address[0] });


            contract.methods.queueTransaction(
                MASTERCHEF, "", signParaTransfer, queuedTime
            ).send({ from: address[0], gasPrice, gas, value })
                .then(async (result) => {
                    await AdminService.addFunction(data);
                    dispatch(stopLoading());
                    toast.success('Saved successfully!!');
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(stopLoading());

                })

        } catch (error) {
            dispatch(stopLoading());
            console.log(error.message);
            return toast.error(error.message);
        }
    };
}

export function executeTransaction(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const web3 = await EthereumService.callWeb3();

            const address = await web3.eth.getAccounts();

            const contract = await ContractServices.callContract(MAIN_CONTRACT_LIST.timeLock.address, MAIN_CONTRACT_LIST.timeLock.abi);

            const gasPrice = await ContractServices.calculateGasPrice();
            const value = await web3.utils.toHex(0);
            const gas = await contract.methods.executeTransaction(MASTERCHEF, "", data.signParaTransfer, data.queuedTime).estimateGas({ from: address[0] });


            contract.methods.executeTransaction(
                MASTERCHEF, "", data.signParaTransfer, data.queuedTime
            ).send({ from: address[0], gasPrice, gas, value })
                .then(async (result) => {
                    await AdminService.updateFunction({ id: data._id });
                    dispatch(stopLoading());
                    toast.success('Executed successfully!!');
                    window.location.reload();
                })
                .catch((error) => {
                    dispatch(stopLoading());
                    return toast.error(error.message);
                })
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(error.message);
        }
    };
}

export function cancelTransaction(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const web3 = await EthereumService.callWeb3();
            const address = await web3.eth.getAccounts();
            const contract = await ContractServices.callContract(MAIN_CONTRACT_LIST.timeLock.address, MAIN_CONTRACT_LIST.timeLock.abi);

            const gasPrice = await ContractServices.calculateGasPrice();
            const value = await web3.utils.toHex(0);
            const gas = await contract.methods.cancelTransaction(MASTERCHEF, "", data.signParaTransfer, data.queuedTime).estimateGas({ from: address[0] });


            contract.methods.cancelTransaction(
                MASTERCHEF, "", data.signParaTransfer, data.queuedTime
            ).send({ from: address[0], gasPrice, gas, value })
                .then(async (result) => {
                    await AdminService.updateFunction({ id: data._id });
                    dispatch(stopLoading());
                    toast.success('Cancled successfully!!');
                    window.location.reload();
                })
                .catch((error) => {
                    dispatch(stopLoading());
                    return toast.error(error.message);
                })
            dispatch(stopLoading());
        } catch (error) {
            dispatch(stopLoading());
            console.log(error.message);
            return toast.error(error.message);
        }
    };
}
